const local = {
  basename: '/smeg',
  stripe_public_key: 'pk_test_TQHhtRXD5aIjBzJdUXtu4Sy100nYJbcoTy'

}

const dev = {
  basename: '/ps30-smeg',
  stripe_public_key: 'pk_test_TQHhtRXD5aIjBzJdUXtu4Sy100nYJbcoTy'
}

const prod = {
  basename: '/smeg',
  stripe_public_key: 'pk_live_oDtDpm2KBVgkmK4rE6BaVfgb00FydnkUH9'
}

const config = process.env.REACT_APP_STAGE === 'local' ? local : (process.env.REACT_APP_STAGE === 'dev' ? dev : prod);


export const general = config;
